/* eslint-disable max-len */

import { request } from './request'

export const catalogApi = {
  getCategories: () => request('GET', '/categories?pagination[start]=0&pagination[limit]=100&filters[parent_category][id][$null]=true&populate=image&filters[empty]=false'),
  getMainBanner: () => request('GET', '/banner-setting?populate=image&populate=category'),
  getBotSettings: () => request('GET', '/bot-setting'),
  getProductsByCategory: (params) =>
    request('GET', `/products?pagination[start]=${params.paginationStart}&pagination[limit]=10&populate[category][populate][0]=parent_category&populate=images&filters[$or][0][category][parent_category][id]=${params.categoryId}&filters[$or][1][category][id]=${params.categoryId}`),
  getProductsBySubCategory: (params) =>
    request('GET', `/products?pagination[start]=${params.paginationStart}&pagination[limit]=10&populate[category][populate][0]=parent_category&populate=images&filters[category][id]=${params.categoryId}`),
  getSubCategoriesByCategory: (params) =>
    request('GET', `/categories?pagination[start]=0&populate=parent_category&pagination[limit]=100&filters[parent_category][id]=${params.categoryId}`),
  getInfoAboutCategory: (id) => request('GET', `/categories/${id}?populate=parent_category`),
  getProductInfo: (id) => request('GET', `/products/${id}?populate[category][populate][0]=parent_category&populate=images`),
  search: (query) => request('GET', `/fuzzy-search/search?query=${query}`),
  getBasket: (userId) => request('GET', `/carts?userId=${userId}`),
  addProductsToBasket: (data) => request('POST', '/carts', data),
  createOrder: (data) => request('POST', '/carts/invoice', data),
  getPaymentsMethods: () => request('GET', '/payments'),
  getDeliveryInfo: () => request('GET', '/delivery'),
  getDeliveryAddress: () => request('GET', `/address-deliveries/${window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || 229165046}`),
  getOrdersHistory: () => request('GET', '/history', { userId: window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || 229165046 }),
  getBranches: () => request('GET', '/branches'),
  applyPromocode: (data) => request('POST', '/promocodes', data),
  applyLoyaltyCard: (data) => request('GET', '/cards/', data),
  authUser: (data) => request('POST', '/auth/telegram', data),
}